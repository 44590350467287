.ui-formik-text-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Rethink-Sans-Medium", sans-serif;

  &__input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 6.4rem;
  }

  &__input {
    width: 100%;
    padding: 2.5rem 1rem 0.5rem 1rem;
    font-size: 1.6rem;
    border: 2px solid transparent;
    border-radius: 8px;
    height: 100%;

    &--error {
      border-color: #ff5050;
      outline: #ff5050;
    }

    &.has-icon {
      padding: 0.6rem 1rem 1rem 3.8rem;
    }

    &:focus-visible:not(&--error) {
      border-color: #007aff;
      outline: #007aff;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-moz-autofill {
      -moz-text-fill-color: #fff;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 1.6rem;
    pointer-events: none;
    transition: all 0.2s ease-out;
  }

  &__input:focus + .ui-formik-text-input__label,
  &__input.has-value + .ui-formik-text-input__label {
    top: 1rem;
    transform: translateY(0);
    font-size: 1.4rem;
    left: 1rem;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.2rem;
    pointer-events: none;
  }

  &__error {
    color: #ff5050;
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }

  & .ui-formik-text-input__input {
    color: #fff;
    background-color: #0f0f0f;
  }

  & .ui-formik-text-input__label {
    color: rgba(255, 255, 255, 0.5);
  }

  & .ui-formik-text-input__icon svg path {
    fill: #fff;
  }

  & .ui-formik-text-input__input:-webkit-autofill,
  & .ui-formik-text-input__input:-moz-autofill,
  & .ui-formik-text-input__input:-internal-autofill-selected {
    background-color: #0f0f0f !important;
    -webkit-text-fill-color: #fff !important;
    -moz-text-fill-color: #fff !important;
    color: #fff !important;
  }

  // @media (prefers-color-scheme: light) {
  //   &__input:focus + .ui-formik-text-input__label,
  //   &__input.has-value + .ui-formik-text-input__label {
  //     color: rgba(16, 16, 16, 0.5);
  //   }

  //   & .ui-formik-text-input__input {
  //     background-color: #f7f7f7;
  //     color: #0f0f0f;
  //   }

  //   & .ui-formik-text-input__label {
  //     color: #979797;
  //   }

  //   & .ui-formik-text-input__icon svg path {
  //     fill: #0f0f0f;
  //   }

  //   & .ui-formik-text-input__input:-webkit-autofill,
  //   & .ui-formik-text-input__input:-moz-autofill,
  //   & .ui-formik-text-input__input:-internal-autofill-selected {
  //     background-color: #f7f7f7 !important;
  //     -webkit-text-fill-color: #0f0f0f !important;
  //     -moz-text-fill-color: #0f0f0f !important;
  //     color: #0f0f0f !important;
  //   }
  // }
}
