@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ambassador-profile-image {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ambassador-upload {
    display: none;
  }
  .ambassador-image {
    position: absolute;
    width: 165px;
    height: 165px;
    z-index: 0;
    object-fit: cover;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
  }
  .ambassador-label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 165px;
    width: 165px;
    margin-bottom: 0px;
  }
  &:hover {
    .ambassador-label {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100000;
      color: #fff;
      transition: background-color 0.2s ease-in-out;
      border-radius: 100px;
      margin-bottom: 0px;
    }
  }
  .ambassador-edit {
    padding: 13px 3px 3px 3px;
    height: 32px;
    font-size: 15px;
  }
  .ambassador-pencil-icon {
    margin-bottom: 13px;
  }
}
