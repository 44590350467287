.redeem-gifting {
  .header-brand-img-container {
    @media (max-width: 920px) {
      height: 150px;
    }
    @media (max-width: 720px) {
      height: 80px;
    }
    @media (max-width: 600px) {
      height: 148px;
    }
  }
  .offers-brand-main-around {
    background-repeat: no-repeat;
    background-position: top;
  }
  .main-container {
    @media (max-width: 600px) {
      padding: 30px 45px;
    }
  }
  .grid-container {
    max-width: 600px;
  }
  .welcome-container {
    max-width: 600px;
    .offer-claim-header {
      font-size: 55px;
      @media (max-width: 600px) {
        font-size: 36px;
      }
    }
  }
  @media (max-width: 600px) {
    .superheader,
    .welcome-bonus-restrictions {
      font-size: 14px;
    }
  }
  .qr-code-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 5px;
    background: #212121;
    padding: 15px;
    margin-top: 19px;
    color: #fff;
    font-family: "Rethink-Sans-Bold", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.479px;
    letter-spacing: 2.148px;
    text-transform: uppercase;
    .clip-to-save {
      cursor: pointer;
      @media (max-width: 600px) {
        width: 11.25px;
        height: 12.75px;
      }
    }
    @media (max-width: 600px) {
      padding: 12.413px 9.858px 12.413px 11.892px;
      line-height: normal;
      letter-spacing: 1.541px;
      text-transform: uppercase;
      margin-top: 13px;
      gap: 13px;
    }
  }
  .highlight {
    color: #eeb804;
    margin-right: 14px;
  }
  .success-toast {
    position: absolute;
    top: 31px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 36px);
    max-width: 494px;
    @media (max-width: 600px) {
      position: fixed;
      top: 40px;
    }
    .success-toast__wrapper {
      position: unset;
    }

    .success-toast__message {
      color: #000;
    }
  }

  .download-link-container {
    color: #fff;
    font-family: "Rethink-Sans-Regular", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    @media (max-width: 600px) {
      width: 100%;
    }
    .sub-title {
      font-size: 24px;
      font-family: "Rethink-Sans-Bold", sans-serif;
      margin-bottom: 2px;
      line-height: 38.519px;
    }
  }
  .download-link-body {
    display: block;
  }
  .download-links-desktop {
    height: auto;
    border: unset;
    background-color: unset;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 600px) {
      display: none;
    }
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 242px;
    }
    .code-container {
      display: flex;
    }
    .qrcode {
      width: 120px;
      height: 120px;
      @media (max-width: 920px) {
        width: 80px;
        height: 80px;
      }
    }
    .download-app-btns {
      justify-content: space-between;
    }
    .download-button {
      border-radius: 13.241px;
      background: #202020;
      width: 140px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
      @media (max-width: 920px) {
        height: 37px;
      }
    }
    .two-phones {
      height: 280px;
      width: auto;
      @media (max-width: 920px) {
        height: 216px;
      }
    }
  }
  .download-links-mobile {
    height: auto;
    border: unset;
    background-color: unset;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    .two-phones {
      width: 100%;
      max-width: 297px;
      margin-top: 13px;
    }
    .sub-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 5px;
    }
    .download-button {
      width: 100%;
      margin: 17px 0;
      border-radius: 5px;
      background: #fff;
      img {
        width: 100%;
        max-height: 53px;
      }
    }
    @media (max-width: 600px) {
      display: flex;
    }
  }
  .map-show-arrow {
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
  .inkind-map {
    width: 100%;
    height: 720px;
    max-height: 90vh;
    margin: 0 auto;
    display: block;
  }
}
