.success-toast {
  position: relative;
  z-index: 10;

  &--hidden .success-toast__wrapper {
    animation: success-toast__hide 1s ease forwards;
  }

  &__wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    animation: success-toast__show 1s ease forwards;
  }

  &__content {
    background: #fff;
    padding: 20px 15px 20px 20px;
    border-radius: 10px;
    border-left: 5px solid #2ecc71;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
    width: 400px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content--offline {
    border-color: #ddd;
  }

  &__icon {
    font-size: 25px;
    color: #f6f6f6;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2ecc71;
    flex-shrink: 0;
  }

  &__icon--offline {
    background: #f6f6f6;
  }

  &__icon-image {
    width: 24px;
    height: 24px;
  }

  &__message-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-left: 15px;
  }

  &__message span {
    font-size: 20px;
    font-weight: 500;
  }

  &__message p {
    color: #999;
  }

  &__sub-message {
    color: #383838;
    font-family: "Rethink-Sans-Regular", "inKind", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  &__close-button {
    color: #999;
    font-size: 23px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    transition: all 0.3s ease;
    margin-left: 20px;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &__close-button:hover {
    background: #f6f6f6;
  }

  &__close-icon {
    line-height: 28px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: "Rethink-Sans-Medium", "inKind", sans-serif;
  }

  @keyframes success-toast__show {
    0% {
      transform: translateX(-100%);
    }
    40% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes success-toast__hide {
    0% {
      transform: translateX(20px);
    }
    40% {
      transform: translateX(10%);
    }
    100% {
      opacity: 0;
      pointer-events: none;
      transform: translateX(-100%);
    }
  }
}
