.radio-button__container {
  display: flex;
  align-items: center;
}

.radio-button__input[type="radio"] {
  display: none;

  &:disabled + .radio-button__label {
    cursor: default;
  }

  &:checked + .radio-button__label .radio-button__custom {
    background-color: #007aff;
    border-color: #007aff;
    // @media (prefers-color-scheme: light) {
    //   background-color: #03c095;
    //   border-color: #03c095;
    // }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 3px;
      width: 12px;
      height: 10px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none"><path d="M11.6542 2.16583L5.25051 8.70757C4.78024 9.18797 4.0198 9.18797 3.55454 8.70757L0.352702 5.4367C-0.117567 4.95629 -0.117567 4.17946 0.352702 3.70416C0.822971 3.22886 1.58341 3.22375 2.04867 3.70416L4.40002 6.1062L9.9532 0.428178C10.4235 -0.0522311 11.1839 -0.0522311 11.6492 0.428178C12.1144 0.908587 12.1194 1.68542 11.6492 2.16072L11.6542 2.16583Z" fill="white"/></svg>');
      background-size: cover;
    }
  }
}

.radio-button__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-family: "Rethink-Sans-Medium", sans-serif;
  font-size: 1.6rem;
  margin-bottom: 0;
  // @media (prefers-color-scheme: light) {
  //   color: #282828;
  // }

  .radio-button__custom {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid #8b888d;
    border-radius: 50%;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    &::after {
      content: "";
      display: none;
    }
  }
}
